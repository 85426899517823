@font-face {
  font-family: "Graphie";
  src: url("/fonts/Graphie-Light.otf");
  font-weight: 350;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: "Graphie";
  src: url("/fonts/Graphie-Book.otf");
  font-weight: 400;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: "Graphie";
  src: url("/fonts/Graphie-SemiBold.otf");
  font-weight: 600;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: "Graphie";
  src: url("/fonts/Graphie-Bold.otf");
  font-weight: 700;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: "Roboto";
  src: url("/fonts/Roboto-Regular.ttf");
  font-weight: 400;
  font-style: normal;
  font-display: swap;
}
